const QuickStartBase = '/quick-start';
export const QuickStart = {
  Base: QuickStartBase,
  Settings: `${QuickStartBase}/settings`,
  New: `${QuickStartBase}/new`,
  LinkedUsers: `${QuickStartBase}/linked-users`, // Has route parameter (/:customer_app_id)
  Guide: `${QuickStartBase}/guide`,
  Customizations: `${QuickStartBase}/ui-customizations`,
};

export const Application = {
  Base: '/applications',
  Settings: '/applications/settings',
};

const TeamsBase = '/teams';
export const Teams = {
  Base: TeamsBase,
  AddMember: `${TeamsBase}/add-member`,
  AddTeam: `${TeamsBase}/add-team`,
  Settings: `${TeamsBase}/settings`,
};

const ReportsBase = '/reports';
export const Reports = {
  Base: ReportsBase,
  Mandates: `${ReportsBase}/mandates`,
  Payments: `${ReportsBase}/payments`,
  Payouts: `${ReportsBase}/payouts`,
};

const IntegrationsBase = '/settings/integrations';
export const Integrations = {
  Base: IntegrationsBase,
  Xero: `${IntegrationsBase}/xero`,
};

const LoginBase = '/login';
export const Login = {
  Base: LoginBase,
  XeroLogin: `${LoginBase}/xero`,
};

const Routes = {
  Login: Login,
  Logout: '/logout',
  Overview: '/overview',
  Register: '/register',
  QuickStart: QuickStart,
  Reports: Reports,
  Application: Application,
  Customizations: '/customizations',
  Verified: '/verified',
  Callback: '/callback',
  Echo: '/echo',
  Error: '/error', // Has route parameter (/:message)
  Home: '/',
  Docs: 'https://docs.finverse.com/',
  Teams: Teams,
  Integrations: Integrations,
};

export default Routes;
