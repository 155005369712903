/**
 * Keep SWR based stuffs here
 */

import { useContext, useMemo } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import useSWR from 'swr';

import { AppContext } from '../contexts/AppContext';
import useTeam from '../hooks/useTeam';

export const GetInstitutions = () => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/institutions`,
    }),
    [token, env],
  );
  const resp = useSWR(config, fetcher, { shouldRetryOnError: false });
  return resp;
};

export const GetCustomerApps = () => {
  const { app } = useContext(AppContext);
  const { team } = useTeam();
  const { env, tokens } = app;
  const token = tokens.get(env) || '';
  const teamId = team.team_id;

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/team/${teamId}/customers`,
    }),
    [token, env, teamId],
  );
  const resp = useSWR(config, fetcher, { shouldRetryOnError: false });
  return resp;
};

const getPre = (query: string) => (query === '' ? '?' : '&');

function getQueryParams(options: MandateOrPaymentQuery): string {
  let query = '';
  if (options.statuses !== undefined && options.statuses.length > 0) {
    query += `${getPre(query)}statuses=${options.statuses.join(',')}`;
  }
  if (options.from !== undefined) {
    query += `${getPre(query)}date_from=${options.from}`;
  }
  if (options.to !== undefined) {
    query += `${getPre(query)}date_to=${options.to}`;
  }
  if (options.senderType !== undefined) {
    query += `${getPre(query)}sender_type=${options.senderType}`;
  }
  if (options.userId !== undefined) {
    query += `${getPre(query)}user_id=${options.userId}`;
  }
  if (options.institutionId !== undefined) {
    query += `${getPre(query)}institution_id=${options.institutionId}`;
  }
  if (options.limit !== undefined) {
    query += `${getPre(query)}limit=${options.limit}`;
  }
  if (options.offset !== undefined) {
    query += `${getPre(query)}offset=${options.offset}`;
  }
  if (options.paymentType !== undefined) {
    query += `${getPre(query)}payment_type=${options.paymentType}`;
  }
  if (options.mandateId !== undefined) {
    query += `${getPre(query)}mandate_id=${options.mandateId}`;
  }
  if (options.currency !== undefined) {
    query += `${getPre(query)}currency=${options.currency}`;
  }
  return query;
}

export const getPayoutQueryParams = (options: PayoutQuery): string => {
  let query = '';
  if (options.statuses !== undefined && options.statuses.length > 0) {
    query += `${getPre(query)}statuses=${options.statuses.join(',')}`;
  }
  if (options.from !== undefined) {
    query += `${getPre(query)}date_from=${options.from}`;
  }
  if (options.to !== undefined) {
    query += `${getPre(query)}date_to=${options.to}`;
  }
  if (options.currency !== undefined && options.currency !== '') {
    query += `${getPre(query)}currency=${options.currency}`;
  }

  if (options.limit !== undefined) {
    query += `${getPre(query)}limit=${options.limit}`;
  }
  if (options.offset !== undefined) {
    query += `${getPre(query)}offset=${options.offset}`;
  }

  return query;
};

export type MandateOrPaymentQuery = {
  statuses?: string[];
  from?: string;
  to?: string;
  senderType?: string;
  userId?: string;
  institutionId?: string;
  limit?: number;
  offset?: number;

  // These are only relevant for payments
  // but can use ? postfix to mark them optional anyway
  paymentType?: string;
  mandateId?: string;
  currency?: string;
};

export const ListMandates = (customerAppId: string, queryParams: MandateOrPaymentQuery) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const queryString = getQueryParams(queryParams);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/v2/mandates${queryString}`,
    }),
    [token, env, customerAppId, queryString],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};

export const ListPayments = (customerAppId: string, queryParams: MandateOrPaymentQuery) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const queryString = getQueryParams(queryParams);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/payments${queryString}`,
    }),
    [token, env, customerAppId, queryString],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};

export type PayoutQuery = {
  statuses?: string[];
  from?: string;
  to?: string;
  currency?: string;

  limit?: number;
  offset?: number;
};

export const ListPayouts = (customerAppId: string, queryParams: PayoutQuery) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const queryString = getPayoutQueryParams(queryParams);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/payouts${queryString}`,
    }),
    [token, env, customerAppId, queryString],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};
