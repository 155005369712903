import { useEffect, useState, ReactElement } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import NoResourcePage from '../../components/NoResourcePage';
import Routes from '../../lib/routes';
import { GetCustomerApps } from '../../lib/useDeveloperApi';
import ViewMandates from './ViewMandates';
import { ViewPayments } from './ViewPayments';
import { ViewPayouts } from './ViewPayouts';

const Reports = (): ReactElement => {
  const [customerAppId, setCustomerAppId] = useState<string>('');
  const { data } = GetCustomerApps();

  // const mandates = [];
  useEffect(() => {
    if (data !== undefined) {
      // always use the first customer_app which is not quickstart
      let newCustomerAppValue = '';
      for (const customerApp of data) {
        if (customerApp.quickstart === false) {
          newCustomerAppValue = customerApp.customer_app_id;
          break;
        }
      }
      setCustomerAppId(newCustomerAppValue);
    }
  }, [data]);
  // only render Mandate View if the customerAppId is not empty

  if (customerAppId === '') {
    return (
      <NoResourcePage
        title="Payments"
        subtitle="This team does not have any API credentials associated with it. Please create one to get started"
        buttonText="Get API Credentials"
        buttonHref={Routes.Application.Settings}
      />
    );
  }

  return (
    <Switch>
      <Route exact path={Routes.Reports.Mandates}>
        <ViewMandates customerAppId={customerAppId}></ViewMandates>
      </Route>
      <Route exact path={Routes.Reports.Payments}>
        <ViewPayments customerAppId={customerAppId}></ViewPayments>
      </Route>
      <Route exact path={Routes.Reports.Payouts}>
        <ViewPayouts customerAppId={customerAppId} />
      </Route>
      <Route path={Routes.Reports.Base}>
        <Redirect to={Routes.Reports.Mandates} />
      </Route>
    </Switch>
  );
};

export default Reports;
